<template>
  <!-- 進度條 -->
  <v-dialog
    v-model="dialog"
    persistent
    max-width="96"
  >
    <v-card
      height="96px"
      class="d-flex align-center justify-center flex-column popupbg"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    // Dialog 狀態
    dialog: {
      get() {
        return this.status
      },
      set(newStatus) {
        this.$emit('update-dialog', newStatus)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
