// 首頁版面組件入口
export default {
  // BANNER
  Banner_D1: () => import('../components/banner/Type1.vue'),
  Banner_L1: () => import('../components/banner/Type1.vue'),
  Banner_D2: () => import('../components/banner/Type2.vue'),
  Banner_L2: () => import('../components/banner/Type2.vue'),
  Banner_D3: () => import('../components/banner/Type3.vue'),
  Banner_L3: () => import('../components/banner/Type3.vue'),
  Banner_D4: () => import('../components/banner/Type4.vue'),
  Banner_L4: () => import('../components/banner/Type4.vue'),
  Banner_D5: () => import('../components/banner/Type5.vue'),
  Banner_L5: () => import('../components/banner/Type5.vue'),
  // 泰極客製
  Banner_D6: () => import('../components/banner/Type6.vue'),
  intro_A: () => import('../components/thaigi/AppInfo.vue'),
  dtrank_A: () => import('../components/thaigi/UserBonus.vue'),
  intro_B: () => import('../components/thaigi/AppDownload.vue'),

  // Game
  GameL1: () => import('../components/game/Type1.vue'),
  GameD1: () => import('../components/game/Type1.vue'),
  GameL2: () => import('../components/game/Type2.vue'),
  GameD2: () => import('../components/game/Type2.vue'),
  GameL3: () => import('../components/game/Type3.vue'),
  GameD3: () => import('../components/game/Type3.vue'),

  // 分類版塊 (七彩)
  GamelistL1: () => import('../components/game/Type4.vue'),
  GamelistD1: () => import('../components/game/Type4.vue'),
  // 泰極客製
  GameD4: () => import('../components/game/Type5.vue'),

  // Menu
  LiveL1: () => import('../components/menu/LiveType1.vue'),
  LiveD1: () => import('../components/menu/LiveType1.vue'),
  LiveL2: () => import('../components/menu/LiveType2.vue'),
  LiveD2: () => import('../components/menu/LiveType2.vue'),
  LiveL3: () => import('../components/menu/allGameType1.vue'),
  LiveD3: () => import('../components/menu/allGameType1.vue'),

  CardL1: () => import('../components/menu/CardType1.vue'),
  CardD1: () => import('../components/menu/CardType1.vue'),
  CardL2: () => import('../components/menu/CardType2.vue'),
  CardD2: () => import('../components/menu/CardType2.vue'),
  CardL3: () => import('../components/menu/allGameType1.vue'),
  CardD3: () => import('../components/menu/allGameType1.vue'),

  LotteryL1: () => import('../components/menu/LotteryType1.vue'),
  LotteryD1: () => import('../components/menu/LotteryType1.vue'),
  LotteryL2: () => import('../components/menu/LotteryType2.vue'),
  LotteryD2: () => import('../components/menu/LotteryType2.vue'),
  LotteryL3: () => import('../components/menu/allGameType1.vue'),
  LotteryD3: () => import('../components/menu/allGameType1.vue'),

  FishL1: () => import('../components/menu/FishType1.vue'),
  FishD1: () => import('../components/menu/FishType1.vue'),
  FishL2: () => import('../components/menu/FishType2.vue'),
  FishD2: () => import('../components/menu/FishType2.vue'),
  FishL3: () => import('../components/menu/allGameType1.vue'),
  FishD3: () => import('../components/menu/allGameType1.vue'),

  SlotD1: () => import('../components/menu/allGameType1.vue'),
  SlotL1: () => import('../components/menu/allGameType1.vue'),

  // 體電 sport eSport (共用 SportType1 SportType2)
  sportL1: () => import('../components/sport/SportType1.vue'),
  sportD1: () => import('../components/sport/SportType1.vue'),
  sportL2: () => import('../components/sport/SportType2.vue'),
  sportD2: () => import('../components/sport/SportType2.vue'),

  esportL1: () => import('../components/sport/SportType1.vue'),
  esportD1: () => import('../components/sport/SportType1.vue'),
  esportL2: () => import('../components/sport/SportType2.vue'),
  esportD2: () => import('../components/sport/SportType2.vue'),

  // 雷火賽事
  sportL3: () => import('../components/competition/CompetitionType1.vue'),
  sportD3: () => import('../components/competition/CompetitionType1.vue'),
  sportL4: () => import('../components/competition/CompetitionType2.vue'),
  sportD4: () => import('../components/competition/CompetitionType2.vue'),
  sportL5: () => import('../components/competition/CompetitionType3.vue'),
  sportD5: () => import('../components/competition/CompetitionType3.vue'),
  sportL6: () => import('../components/competition/CompetitionType4.vue'),
  sportD6: () => import('../components/competition/CompetitionType4.vue'),
  sportL7: () => import('../components/competition/CompetitionType5.vue'),
  sportD7: () => import('../components/competition/CompetitionType5.vue'),

  esportL3: () => import('../components/competition/CompetitionType1.vue'),
  esportD3: () => import('../components/competition/CompetitionType1.vue'),
  esportL4: () => import('../components/competition/CompetitionType2.vue'),
  esportD4: () => import('../components/competition/CompetitionType2.vue'),
  esportL5: () => import('../components/competition/CompetitionType3.vue'),
  esportD5: () => import('../components/competition/CompetitionType3.vue'),
  esportL6: () => import('../components/competition/CompetitionType4.vue'),
  esportD6: () => import('../components/competition/CompetitionType4.vue'),
  esportL7: () => import('../components/competition/CompetitionType5.vue'),
  esportD7: () => import('../components/competition/CompetitionType5.vue'),

  // 最新遊戲
  NewGamesD1: () => import('./games/NewHotGames.vue'),
  HotGamesD1: () => import('./games/NewHotGames.vue'),
  FeatureGameD: () => import('./games/NewHotGames.vue'),
}
