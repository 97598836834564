<template>
  <!-- 推薦禮金 -->
  <div class="pt-56">
    <v-container>
      <!-- recommend -->
      <v-row class="pt-2 px-3 pb-2">
        <v-col
          v-for="(item, index) in recommendItem"
          :key="index"
          class="pa-1 text-center"
          cols="4"
        >
          <div class="card1 py-2 rounded d-flex align-center flex-column justify-center caption">
            <div class="recommend__title title--text font-weight-medium">
              {{ item.title }}
            </div>

            <div
              class="recommend__num primary--text text-h6"
              :class="[{'secondary--text': item.item === 'total'}]"
            >
              {{ recommend[item.item] }}
            </div>

            <div class="recommend__description hint--text">
              {{ item.description }}
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="px-1">
        <v-col cols="12">
          <v-divider class="divider" />
        </v-col>
      </v-row>

      <!-- 說明文字 -->
      <v-row id="recommend-info">
        <v-col cols="2" />
        <v-col
          cols="8"
          class="text-center title--text"
        >
          <div class="description__title text-h6">
            {{ $t('recommend.title') }}
          </div>

          <div class="description__subtitle subtitle-1 mb-6">
            {{ $t('recommend.subtitle') }}
          </div>

          <div class="description__description caption hint--text">
            {{ $t('recommend.description') }}
          </div>
        </v-col>

        <v-col cols="2" />

        <!-- QRCODE -->
        <v-col cols="12 text-center">
          <QrcodeVue
            class="pt-4 px-4 pb-2 white qrcode rounded"
            :value="recommendResgisterUrl"
            size="92"
            level="L"
            foreground="black"
          />
        </v-col>
      </v-row>

      <!-- 推薦碼 -->
      <v-row class="px-3 pt-3">
        <v-col cols="2" />
        <!-- 文字 -->
        <v-col
          cols="8"
          class="py-0 caption title--text px-1"
        >
          {{ $t('global.recommendCode') }}
        </v-col>
        <v-col cols="2" />

        <v-col cols="2" />
        <v-col
          cols="8"
          class="py-0 px-1"
        >
          <!-- 推薦碼 -->
          <v-text-field
            autofocus
            readonly
            class="mt-2 red--text"
            min-height="36"
            dense
            outlined
            required
            :value="recommendCode"
          >
            <!-- 復製推薦碼 -->
            <div
              v-show="false"
              slot="append"
            >
              <v-btn
                icon
                height="20"
                width="20"
                @click="copyNumber('recommendCode')"
              >
                <Icon
                  data="@icon/copy.svg"
                  width="16"
                  height="16"
                  class="title--text"
                />
              </v-btn>
            </div>
          </v-text-field>
        </v-col>
        <v-col cols="2" />

        <!-- 操作區塊 -->
        <v-col
          cols="12"
          class="py-0 px-1 text-center pb-5"
        >
          <!-- 復製連結 btn -->
          <v-btn
            slot="btn"
            color="success"
            text
            height="64"
            @click="copyNumber('recommendResgisterUrl')"
          >
            <div class="d-flex flex-column align-center justify-center">
              <Icon
                data="@icon/copylink.svg"
                width="24px"
                height="24px"
                class="icon--text"
              />
              <span class="caption title--text mt-2">{{ $t('global.copyRecommendUrl') }}</span>
            </div>
          </v-btn>

          <!-- 下載 btn -->
          <a
            :href="qrCodeCanvasImgUrl"
            download="recommend-qrcode"
            class="text-decoration-none"
          >
            <v-btn
              slot="btn"
              color="success"
              text
              height="64"
            >
              <div class="d-flex flex-column align-center justify-center">
                <Icon
                  data="@icon/download.svg"
                  width="24px"
                  height="24px"
                  class="icon--text"
                />
                <span class="caption title--text mt-2">{{ $t('global.downloadQRcode') }}</span>
              </div>
            </v-btn>
          </a>
        </v-col>

        <!-- 推薦碼 input (HIDDEN) -->
        <input
          id="recommendCode"
          type="text"
          :value="recommendCode"
        >
        <!-- 推薦連結 input (HIDDEN) -->
        <input
          id="recommendResgisterUrl"
          type="text"
          :value="recommendResgisterUrl"
        >
      </v-row>
    </v-container>
    <div class="d-none" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '../../utils/resUtils'
import { copyNumber } from '@/utils/copy'
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas'

export default {
  components: {
    QrcodeVue,
  },

  data: () => ({
    recommend: {
      bonus: '--',
      settlement: '--',
      total: '--',
    },

    qrCodeCanvasImgUrl: null,
  }),

  computed: {
    ...mapState({
    // 使用者推薦碼
      recommendCode: (state) => state.user.userData.recommend_code,
    }),
    ...mapGetters(['currencySymbol']),

    /**
     * 註冊頁面 query 推薦碼
     * @date 2021-06-01
     */
    recommendResgisterUrl() {
      const url = window.location.origin

      const { href } = this.$router.resolve({
        name: 'register',
        query: {
          recommend: this.recommendCode,
        },
      })

      return `${url}/${href}`
    },

    /**
     * 推薦相關紀錄 (推薦禮金 / 返利奖励 / 返利奖励)
     * item 為 api 的指定項目 (this.recommend)
     * @date 2021-06-01
     * @returns {array}
     */
    recommendItem() {
      return [
        {
          // 推薦禮金
          title: this.$t('myCenter.recommendBonus') + ' ' + this.$t(`global.${this.currencySymbol}`),
          // 說明文字
          description: this.$t('myCenter.totalAmountofIssued'),
          // API 項目
          item: 'bonus',
        },
        {
          // 返利奖励
          title: this.$t('myCenter.returnBonus') + ' ' + this.$t(`global.${this.currencySymbol}`),
          // 已结算总金额
          description: this.$t('myCenter.totalAmountofSettled'),
          // API 項目
          item: 'settlement',
        },
        {
          // 返利奖励
          title: this.$t('myCenter.returnBonus') + ' ' + this.$t(`global.${this.currencySymbol}`),
          // <!-- 未结算总金额 -->
          description: this.$t('myCenter.totalAmountofUnsettled'),
          // API 項目
          item: 'total',
        },
      ]
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.recommendBonus'))
    this.getUserRecommend()
    this.createDownloadImg()
  },

  methods: {
    ...mapActions(['set_toolbar_title', 'get_user_recommend']),

    /**
     * 取得玩家推薦禮金概要(成功)
     * @date 2021-05-31
     * @param {any} {recommand_bonus} 推薦禮金資料
     */
    getUserRecommendSuccess({ recommand_bonus }) {
      this.recommend = recommand_bonus
    },

    /**
     * 取得玩家推薦禮金概要
     * @date 2021-05-31
     * @returns {object} 玩家推薦禮金資料
     */
    async getUserRecommend() {
      const res = await this.get_user_recommend()
      resStatus(res, this.getUserRecommendSuccess)
    },

    /**
     * 復製文字
     * @date 2021-06-01
     * @param {string} id 需要復製文字的 input id
     */
    copyNumber(id) {
      copyNumber(id)
    },

    /**
     * 將 qrcode 區塊(canvas 的元素) 轉為 jpg 格式 寫入 data
     * @date 2021-08-12
     */
    async createDownloadImg() {
      const qrcodeImg = await this.html2Canvas('.qrcode')
      const qrCodeCanvasImgUrl = qrcodeImg.toDataURL('image/jpeg')

      this.qrCodeCanvasImgUrl = qrCodeCanvasImgUrl
    },

    /**
     * html 轉為 CANVAS, 且將元素插入 document (不顯示)
     * @date 2021-08-12
     * @param {any} qrcodeDomClassName
     * @returns {dom} 轉為 canvas 的元素
     */
    async html2Canvas(qrcodeDomClassName) {
      const canvas = await html2canvas(document.querySelector(qrcodeDomClassName))
      canvas.classList.add('qrcode-canvas-img')
      document.querySelector('.d-none').appendChild(canvas)
      canvas.classList.add('d-none')
      return document.querySelector('.qrcode-canvas-img')
    },

  },
}
</script>

<style lang="scss" scoped >
.h-88 {
	height: 88px;
}

#recommendResgisterUrl,
#recommendCode {
	height: 0;
}

.qrcode {
	display: inline-block;
}

.d-none {
	display: none;
}
</style>
