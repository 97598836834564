<template>
  <div class="d-flex align-center justify-center">
    <div class="text-center">
      <v-img
        width="240"
        src="../../assets/image/nodata.png"
      />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>
