import { getWalletBalanceAPI } from '@/api'
import { spec } from '../../utils/money'

const state = () => ({
  main: '0.00',
  locked: '0.00',
})

const getters = {}

const mutations = {
  SET_MAIN_BALANCE(state, balance) {
    const balanceFormat = spec(balance)
    state.main = balanceFormat
  },

  SET_LOCKED_BALANCE(state, balance) {
    const balanceFormat = spec(balance)
    state.locked = balanceFormat
  },
}

const actions = {
  async get_user_balance({ commit }, walletCode) {
    const { walletcode } = walletCode
    const { data } = await getWalletBalanceAPI(walletCode)
    if (data) {
      if (walletcode === 'player_balance') {
        commit('SET_MAIN_BALANCE', data.balance)
      } else if (walletcode === 'locked_balance') {
        commit('SET_LOCKED_BALANCE', data.balance)
      }
      return data
    }
  },

  set_main_balance({ commit }, balance) {
    commit('SET_MAIN_BALANCE', balance)
  },
  set_locked_balance({ commit }, balance) {
    commit('SET_LOCKED_BALANCE', balance)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
