<template>
  <div>
    <!-- 工具列 -->
    <Toolbar />

    <!-- vendor 內容 -->
    <v-container>
      <!-- 若無資料 -->
      <v-row
        v-if="layoutData.length < 1"
        class="white--text px-3 vh-100"
        justify="center"
        align="center"
      >
        <Nodata />
      </v-row>

      <!-- 若有資料 -->
      <v-row
        v-else
        class="px-3 pt-72"
      >
        <v-col
          v-for="vendor in activeData.items"
          :key="vendor._id"
          cols="6"
          class="rounded pa-1"
        >
          <!-- vendor -->
          <div
            class="vendor"
            @click="launchGame(vendor, activeData.class)"
          >
            <!-- Vendor 圖片 -->
            <div
              class="vendor__img rounded overflow-hidden"
              :style="style === 'darkGD' ? {border: `solid 2px ${primaryClr}`} : ''"
            >
              <div
                :style="gradientBg"
              >
                <v-img
                  :src="baseImgUrl + vendor.image_h5"
                  height="119px"
                />
              </div>
            </div>

            <!-- Vendor 文字 -->
            <div class="vendor__text px-3 subtitle-2 mt-1 title--text">
              {{ vendor.title }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Toolbar from '../../components/base/Toolbar.vue'
import Nodata from '../../components/base/NodataBig.vue'
import { resStatus } from '../../utils/resUtils'

export default {
  components: { Toolbar, Nodata },

  computed: {
    ...mapState({
      // 所有版塊資料
      layoutData: state => state.indexPageLayoutArea,
      isLogin: state => state.user.isLogin,
    }),

    ...mapGetters(['baseImgUrl', 'gradientBg', 'theme', 'style']),

    primaryClr() {
      return this.$vuetify.theme.themes[this.theme]?.primary
    },

    /**
     * 目前點擊類別
     * @date 2021-12-09
     * @returns {object} 目前點擊類別內的 navbar
     */
    activeData() {
      const activeLayout = this.layoutData.filter(item => item.type === 'games')[0]
      return activeLayout.data.navbar.filter(item => item._id === this.$route.params.id)[0]
    },
  },

  watch: {
    layoutData() {
      this.setToolbar()
    },
  },

  mounted() {
    if (this.layoutData.length > 0) this.setToolbar()
  },

  methods: {
    ...mapActions(['set_toolbar_title', 'get_game_url_by_device', 'show_alert']),

    /**
     * 設置 toolbar title
     * @date 2021-12-09
     */
    setToolbar() {
      this.set_toolbar_title(this.activeData.class.toUpperCase() + ' ' + this.activeData.game_type)
    },

    /**
     * 取得遊戲連結 (成功)
     * @date 2021-12-09
     * @param {string} gameUrl
     */
    getGameUrlSuccess(gameUrl) {
      // 一般模式
      window.open(gameUrl, '_blank')
    },

    /**
     * 取得遊戲連結 (失敗)
     * @date 2021-12-09
     */
    getGameUrlFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 請先登入
     * @date 2021-12-10
     */
    noLogin() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('flashMessage.loginFirst'),
      })
    },

    /**
     * 啟動遊戲 (依裝置) 功能
     * 若 activeClass 為 slot / fish 導至 slot 列表
     * @date 2021-12-09
     * @param {string} {game_code vendor 的 遊戲代碼
     * @param {string} hyperlink} 若為 slot / fish 才有值 遊戲商名稱 (ex: pg icg)
     * @param {string} activeClass='' 目前點擊的商品類別 (ex: live slot fish)
     * @param {function} fun=this.get_game_url_by_device 取得遊戲連結功能 (@store)
     */
    async launchGame({ game_code, hyperlink }, activeClass = '', fun = this.get_game_url_by_device) {
      if (!this.isLogin) return this.noLogin()

      const slotList = ['slot', 'fish']
      if (slotList.includes(activeClass)) {
        return this.$router.push({
          name: 'slot',
          params: {
            slotName: hyperlink,
          },
        })
      }

      // 另開進入遊戲
      const gameUrl = await fun({ gamecode: game_code })
      resStatus(gameUrl, this.getGameUrlSuccess, this.getGameUrlFail)
    },
  },
}
</script>

<style lang="scss" scoped>
.pt-72{
  padding-top: 72px;
}
</style>
