<template>
  <div>
    <Alert
      class="alert"
      :dialog="alert.status"
      :icon="alert.icon"
      :text="alert.text"
    />
    <Progress
      class="progress"
      :status="progress"
    />
    <Toolbar
      v-if="isShowToolbar"
      class="toolbar"
    />
    <router-view />
    <BootomNav v-if="isShowBottmNav" />
  </div>
</template>

<script>
import figlet from 'figlet'
import standard from 'figlet/importable-fonts/Standard.js'

import BootomNav from '@/components/base/BottomNav'
import Alert from '@/components/dialog/Alert'
import { mapActions, mapMutations, mapState } from 'vuex'
import Progress from '@/components/dialog/Progress'
import Toolbar from '../components/base/Toolbar.vue'
import isLocalDev from '../utils/isLocalDev'
import { resStatus } from '../utils/resUtils'

export default {
  components: { BootomNav, Alert, Progress, Toolbar },

  data: () => ({
    // 要 顯示底部導覽列 頁面
    showBottmNav: ['bonus', 'record', 'user', 'home', 'service'],

    // 要 顯示頂部工具列 頁面
    showToolbar: [
      'bonusItem',
      'trade',
      'prize',
      'card',
      'add-card',
      'withdrawal',
      'deposit',
      'vip-rules',
      'news',
      'news-item',
      'help',
      'personal',
      'recommend',
    ],

    // 視窗寬度
    screenWidth: document.body.clientWidth,

    idToken: null,
  }),

  computed: {
    ...mapState({
      alert: state => state.dialog.alert,
      progress: state => state.dialog.progress.status,
    }),

    isShowBottmNav() {
      return this.showBottmNav.includes(this.$route.name)
    },

    isShowToolbar() {
      return this.showToolbar.includes(this.$route.name)
    },
  },

  watch: {
    /**
     * 監聽文件寬度
     * @date 2021-08-10
     * @param {any} resizeWidth
     * @returns {any}
     */
    screenWidth: {
      handler(resizeWidth) {
        // 如果 (橫向>768 或 slot遊戲中) 不轉向 web
        if (resizeWidth < 768 || this.$route.name === 'launchGame') return false
        this.pushWebPage()
      },
      immediate: true, // 初始化即執行
    },
  },

  mounted() {
    // 當視窗調整尺吋時執行
    window.onresize = this.onWindowsResize

    // SHOW CONSOLE 圖形文字
    this.showConsoleText()
  },

  methods: {
    ...mapActions(['line_login', 'set_website', 'set_user_data', 'show_alert', 'get_user_balance', 'set_line_user_data']),
    /**
     * 視窗調整尺吋時執行
     * @date 2021-08-10
     */
    onWindowsResize() {
      this.screenWidth = document.body.clientWidth
    },

    /**
     * 轉至 web 頁面
     * @date 2021-08-10
     */
    pushWebPage() {
      const website = this.isLocalDevEv()

      // 若為註冊頁且帶有推薦碼，則轉註冊頁面
      if (this.$route.name === 'register' && this.$route.query.recommend) {
        location.href = `https://${website}/#/register?recommend=${this.$route.query.recommend}`
      } else location.href = `https://${website}`
    },

    /**
     * 是否為本地開發
     * true env 的 站名
     * false host 字串處理
     * @date 2021-08-13
     * @returns {website} 站名
     */
    isLocalDevEv() {
      const website = isLocalDev()
        ? process.env.VUE_APP_WEBSITE
        : location.host
          .replace('m.', '')
          .replace('v2.', '')
      return website
    },

    showConsoleText() {
      // Error: FIGlet header contains invalid values.
      figlet.parseFont('Standard', standard)

      // figlet('[H5] ' + location.host, { font: 'Standard' }, (err, data) => {
      //   if (err) return console.log(err)
      //   console.log(data)
      // })
    },

    async getUserBalance() {
      const walletcode = { walletcode: 'player_balance' }
      await this.get_user_balance(walletcode)
    },
  },
}
</script>

<style lang="scss" scoped>
.alert {
	z-index: 2;
}
</style>
