import request from '@/api/request'

// // 新增國家城市
// import { CN, VN } from '../utils/cities'

/*
  API 銀行
  銀行相關的 API
    - 取得銀行卡列表
    - 刪除銀行卡
    - 新增銀行卡
    - 取得銀行列表
*/

export const getBankCardList = () => request.get('api/v1/member/bankcard/list')

export const deleteBankCard = data =>
  request.post('api/v1/member/bankcard/delete', data)

export const addBankCard = data =>
  request.post('api/v1/member/bankcard/create', data)

export const getBankList = () => request.get('api/v1/trans/bank-list')
