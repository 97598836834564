// 銀行
import {
  getBankCardListAPI,
  getBankListAPI,
  addBankCardAPI,
  deleteBankCardAPI,
} from '@/api'

// 國家城市
import nationCity from '../../utils/cities'
const getNationCity = (nation) => nationCity[nation]

const state = () => ({})

const getters = {}

const mutations = {}

const actions = {
  async get_user_bankcard_list({ dispatch }) {
    const userBankCardList = await getBankCardListAPI()
    return userBankCardList
  },

  async get_bank_list() {
    const res = await getBankListAPI()
    return res
  },

  /**
   * 越南省份城市格式處理
   * @date 2021-03-30
   * @param {object} vnCity 越南省市資料
   * @returns {object} 格式化 越南省市資料
   */
  vn_city_format({ commit }, vnCity) {
    return vnCity.reduce((acc, cur) => {
      acc = { ...acc }
      cur.province in acc
        ? acc[cur.province].push(cur.city)
        : (acc[cur.province] = [cur.city])
      return acc
    }, {})
  },

  /**
   * 取得國家城市資料
   * @date 2021-07-06
   * @param {object} {dispatch}
   * @param {string} nations 國家名稱 china vn thai
   * @returns {object} 城市資料
   */
  async get_nation_city({ dispatch }, nations) {
    const cityData = await getNationCity(nations)
    if (nations === 'vn') return await dispatch('vn_city_format', cityData.data)
    return cityData
  },

  async create_user_bank_card({ commit }, data) {
    const res = await addBankCardAPI(data)
    return res
  },

  async delete_user_bank_card({ commit }, cardNumber) {
    const res = await deleteBankCardAPI(cardNumber)
    return res
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
