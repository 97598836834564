import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'

import Console from './utils/console'
import i18n from './i18n'
import { VueSvgIcon } from '@yzfe/vue-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'

import './style/index.scss'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter' // 設置時區

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// -------------- swiper -------------- //
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(VueAwesomeSwiper /* { default options with global component } */)

// -------------- dayjs --------------- //
dayjs.extend(utc)
dayjs.extend(timezone)
// 設置時區
dayjs.tz.setDefault(dayjs.tz.guess())
// dayjs.tz.setDefault('Asia/Shanghai')
// timezone => console.log(dayjs.tz().$x.$timezone)
Vue.prototype.$day = dayjs

Vue.config.productionTip = false

/**
 * 在 dev / rc 環境才會console
 * this.$log() / this.$error() / this.$warn()
 * @date 2021-03-04
 * @param {any} 傳入的任何型別
 */
Object.keys(Console).forEach(consoleEvents => {
  Vue.prototype[consoleEvents] = (...params) => {
    if (process.env.VUE_APP_CONSOLE === 'true') { return Console[consoleEvents](...params) }
  }
})

Vue.prototype.$scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

Vue.component('Icon', VueSvgIcon)

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default vue
