import request from '@/api/request'

/*
  API 首頁
  首頁相關的 API
    - 根據 Domain 取得 wtoken
    - 取得 News
    - 取得 選單
    - 取得 Footer
    - 取得 跑馬燈
    - 取得 Banner
    - 取得 基本設定
*/

// 根據 Domain 取得 wtoken
export const getWtoken = data => request.post('api/v1/website', data)

// 取得 News
export const getNews = data => request.post('/api/v1/news', data)

// 取得 站內信
export const getMemberNews = (pageData) => request.post('/api/v1/news-for-member', pageData)

// 更新站內信 狀態 (未讀 -> 已讀)
export const updateMemberNewsStatus = (newsId) => request.post('/api/v1/update-news-for-member-state', newsId)

// 取得 選單
export const getNavbar = () => request.get('api/v1/navbar')

// 取得 Footer
export const getFooter = () => request.get('api/v1/footer')

// 取得 跑馬燈
export const getMarquee = () => request.get('api/v1/marquee')

// 取得 跑馬燈
export const getBanner = () => request.get('api/v1/banner')

// 取得 基本設定
export const getSiteInfo = () => request.get('api/v1/siteinfo')

// 取得新手幫助內的文字
export const getDocument = () => request.get('api/v1/documents')
export const getDocumentDetails = type =>
  request.get(`api/v1/documents-app?type=${type}`)

// 取得風格參數 (主題色 / 深淺模式)
export const getWebsiteStyle = () => request.get('api/v1/home/style')

// 取得 (首頁) 板塊 (依序)
export const getWebsiteIndexLayout = () => request.get('api/v1/home/layout')

// 取得客服連結
export const getServiceUrl = () => request.get('/api/v1/get-chatbox-url')

export const getAppDownloadUrl = (data) => request.post('/api/v1/app-download-url', data)

export const getDTListData = () => request.get('/api/v1/ranking-report')

export const qrcodeLogin = (token) => request.post('/api/v1/auth/qr-login', token)
