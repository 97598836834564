<template>
  <!-- 跑馬燈 -->
  <div
    id="marquree"
    class="card2 px-4 d-flex align-center justify-start"
  >
    <!-- 標誌 -->
    <div class="d-flex justify-center">
      <Icon
        data="@icon/alert.svg"
        width="16"
        height="16"
        class="secondary--text mr-2 marquree__icon"
      />
    </div>

    <!-- 文字 -->
    <div class="marquree__text comment--text subtitle-2">
      <div
        class="text"
        :style="{width: marqueeContentLength * 18 + 'px'}"
      >
        <span
          v-for="marquee in marqueeContent"
          :key="marquee._id"
          class="cursor-pointer"
          @click="marqueeDialog(marquee._id)"
        >
          {{ marquee.content }}
        </span>
      </div>
    </div>

    <!-- Dialogs -->
    <Dialogs
      :width="280"
      :dialog="dialog.status"
      @update-dialog="setDialogStatue"
    >
      <!-- title -->
      <div
        slot="title"
        class="title--text subtitle-1"
      >
        {{ dialog.title }}
      </div>
      <!-- text -->
      <div
        slot="text"
        class="px-6 subtitle-2 text--text"
      >
        {{ dialog.text }}
      </div>
      <!-- BTN -->
      <div
        slot="btn"
      >
        <v-btn
          text
          color="primary"
          @click="dialog.status = false"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </div>
    </Dialogs>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapActions } from 'vuex'
import { resStatus } from '@/utils/resUtils'

import Dialogs from '@/components/dialog/index.vue'

export default {
  components: {
    Dialogs,
  },

  data: () => ({
    marqueeContent: '',
    marqueeContentLength: 0,

    dialog: {
      status: false,
      title: ' -- ',
      text: '...',
    },
  }),

  created() {
    this.getMarquee()
  },

  methods: {
    ...mapActions(['get_marquee']),

    /**
     * 設置彈窗狀態
     * @date 2021-12-15
     * @param {boolean} newStatus 狀態
     */
    setDialogStatue(newStatus) {
      this.dialog.status = newStatus
    },

    /**
     * 取得 跑馬燈 資料(成功)
     * - 設置跑馬燈所有資料
     * - 驗算跑馬燈所有 content 文字長度
     * - 執行跑馬燈動畫
     * @date 2021-06-24
     * @param {object} {marquee} 跑馬燈所有資料
     */
    getMarqueeSuccess({ marquee }) {
      this.marqueeContent = marquee
      this.marqueeContentLength = this.getMarqueeContentLength(marquee)
      this.marqureeAction(this.marqueeContentLength)
    },

    /**
     * 取得 跑馬燈 資料
     * @date 2021-06-24
     */
    async getMarquee() {
      const res = await this.get_marquee()
      resStatus(res, this.getMarqueeSuccess)
    },

    /**
     * 取得 marquee 所有文字長度
     * @date 2021-06-24
     * @param {array} marquee 跑馬燈所有內容
     * @returns {number} marquee 所有文字長度
     */
    getMarqueeContentLength(marquee = []) {
      let allMarqueeContneAry = []
      marquee.forEach(item => {
        allMarqueeContneAry = [...allMarqueeContneAry, item.content]
      })
      return allMarqueeContneAry.join('').length
    },

    /**
     * 跑馬動畫 設置 && 播放
     * @date 2021-06-24
     * @param {number} length marquee 所有文字長度
     */
    marqureeAction(length) {
      // 設置動畫 gsap
      const marquree = gsap.fromTo(
        '.text',
        // from 在畫面寬度之外
        {
          x: document.body.clientWidth,
        },
        // to 加上畫面寬度
        {
          duration: length / 3, // 運行時間
          x: -(length * 12 + document.body.clientWidth),
          repeat: 999,
          ease: 'none',
        },
      )
      // 播放
      marquree.play()
    },

    /**
     * marquee 公告彈窗 (點擊公告)
     * @date 2021-06-25
     * @param {number} marqueeId marquee id
     */
    marqueeDialog(marqueeId) {
      const { title, content } = this.marqueeContent.find(item => item._id === marqueeId)
      this.dialog.title = title
      this.dialog.text = content
      this.dialog.status = true
    },
  },
}
</script>

<style lang="scss" scoped>
#marquree {
	overflow: hidden;
	height: 32px;
}

.marquree {
	&__text {
		overflow-x: scroll;
		width: 1000px;
		height: 32px;
		line-height: 32px;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

</style>
