<template>
  <!-- 客服主頁 -->
  <div>
    <!-- HEADER -->
    <div class="header w-100">
      <!-- 背景色塊 -->
      <div
        class="service-bg"
        :style="customerClr"
      />
      <div class="service-bg-2 primary" />

      <div class="toolbar px-5 py-4 d-flex align-center">
        <!-- 回上一頁 -->
        <v-btn
          icon
          class="pa-0"
          @click="$router.go(-1)"
        >
          <Icon
            data="@icon/toolbar/arrow_left.svg"
            width="16px"
            height="16px"
            class="icon--text"
          />
        </v-btn>

        <!-- toolbar title -->
        <span class="title--text font-weight-medium text-h6 ml-5">
          {{ $t('service.customerService') }}
        </span>
      </div>

      <div
        class="user-area navBar rounded mx-6 px-5 py-4 d-flex align-center"
        :class="[{'h-124px': !isIOS}]"
      >
        <div class="w-100">
          <div class="user-area__info d-flex align-center">
            <div class="user-area__avatar">
              <!-- 預覽頭像 -->
              <v-avatar
                size="60"
                color="primary"
                class="mt-2"
                :style="avatarBorderClr"
              >
                <img
                  v-if="userData.avatar"
                  :src="baseImgUrl + userData.avatar"
                  :alt="userName"
                >
              </v-avatar>
            </div>
            <div class="user-area__info ml-6">
              <div class="subtitle-1 title--text">
                {{ userInfo.title }}
              </div>
              <div class="caption comment--text">
                {{ userInfo.description }}
              </div>
            </div>
          </div>

          <!-- IOS 才看的到 -->
          <div v-show="isIOS">
            <div class="py-3">
              <v-divider class="divider" />
            </div>

            <div class="user-area__description caption comment--text">
              {{ serviceApp.description }}
            </div>

            <div class="text-center mt-3">
              <v-btn
                height="32"
                color="primary"
                @click="goDownloadPage"
              >
                <span class="btnText--text caption">
                  {{ $t('global.downloadNow') }}
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="px-6 mt-4">
        <!-- 在線客服 btn -->
        <v-btn
          block
          height="87"
          class="cal-center-btn"
          :style="customerClr"
          @click="toServicePage"
        >
          <v-container>
            <v-row>
              <v-col cols="8">
                <div class="d-flex">
                  <div>
                    <Icon
                      data="@icon/bottomNav/service.svg"
                      width="24px"
                      height="24px"
                      class="btnText--text "
                    />
                  </div>
                  <div class="ml-2">
                    <!-- 在線客服 -->
                    <div
                      class="btnText--text subtitle-1 text-left"
                      :class="[{ caption: clientLang === 'vi-VN'}]"
                    >
                      {{ $t('global.onlineCs') }}
                    </div>
                    <!-- description 描述 -->
                    <div class="caption icon--text">
                      {{ $t('service.24hourService') }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="4"
                class="d-flex justify-end align-center"
              >
                <v-icon
                  right
                  class="btnText--text"
                >
                  mdi-chevron-right
                </v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '../../utils/resUtils'

import hexToRgba from 'hex-to-rgba'

export default {
  data: () => ({
    userData: {
      avatar: '',
    },

    isIOS: null,
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'clientLang']),

    ...mapState({
      isLogin: state => state.user.isLogin,
      userName: state => state.user.userData.member.account,

      serviceUrl: state => state.serviceUrl,
      theme: state => state.theme,

      userInfo: state => state.service.user,
      serviceApp: state => state.service.serviceApp,
      // 來自 api 的客服設定
      fromApiAboutService: state => state.websiteData.cs_settings,
    }),

    avatarBorderClr() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes[this.theme]?.primary}`,
      }
    },

    /**
     * primary 色 轉 hex
     * @date 2021-10-08
     * @returns {any}
     */
    primaryColor() {
      const color = this.$vuetify.theme.themes[this.theme]?.primary || ''
      return hexToRgba(color)
    },

    /**
     * 客製化漸層色
     * @date 2021-10-08
     * @returns {any}
     */
    customerClr() {
      const computePrimaryClr = this.primaryColor.split(', ')
      const r = computePrimaryClr[0].slice(5, computePrimaryClr[0].length)
      const g = computePrimaryClr[1]
      const b = computePrimaryClr[2]

      return `background-image: linear-gradient(251deg, rgba(${r}, ${g}, ${b}, 1), rgba(${r - 21}, ${g - 28}, ${b - 42}, 1));`
    },

    /**
     * 判斷是否依 api 取得客服 url
     * @date 2021-12-28
     * @returns {boolean} 是否打 api 取得客服 url
     */
    isChatboxUrl() {
      return this.fromApiAboutService.cs_platform_type === 'chatbox'
    },
  },

  mounted() {
    this.deviceCheck()
    if (this.isChatboxUrl) this.getServiceUrl()
    if (this.isLogin) this.getUserBaseInfo()
  },

  methods: {
    ...mapActions([
      'get_service_url',
      'show_alert',
      'get_user_base_info',
    ]),

    /**
     * 取得客服連結 (失敗)
     * @date 2021-08-17
     */
    getServiceUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得客服連結
     * @date 2021-08-17
     */
    getServiceUrl() {
      this.get_service_url()
    },

    /**
     * 另開客服連結頁面
     * @date 2021-08-17
     */
    toServicePage() {
      if (!this.isChatboxUrl && this.fromApiAboutService.cs_direct_url) return window.open(this.fromApiAboutService.cs_direct_url, '_blank')
      if (!this.serviceUrl) return this.getServiceUrlFail()
      const url = this.serviceUrl
      console.log(url)
      window.open(url, '_blank')
      window.webkit.messageHandlers.openCustomService.postMessage(url)
    },

    /**
     * 判斷行動裝置 (ios/android)
     * @date 2021-08-31
     */
    deviceCheck() {
      const device = navigator.userAgent
      const isAndroid = device.indexOf('Android') > -1 || device.indexOf('Adr') > -1
      const isiOS = !!device.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

      if (isiOS) this.isIOS = true
      else this.isIOS = false
    },

    /**
     * 取得使用者資本資料
     * @date 2021-10-08
     * @returns {any}
     */
    getUserBaseInfoSuccess(data) {
      this.userData.avatar = data.member.avatar
    },

    /**
     * 取得使用者資本資料 (頭像)
     * @date 2021-10-08
     */
    async getUserBaseInfo() {
      const userBaseInfo = await this.get_user_base_info()
      resStatus(userBaseInfo, this.getUserBaseInfoSuccess)
    },

    goDownloadPage() {
      const { href } = this.$router.resolve({ path: '/app-store' })

      const url = `${window.location.origin}/${href}`
      try {
        window.webkit.messageHandlers.openDownPage.postMessage(url)
      } catch {
        window.open(url, '_blank')
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.header {
	position: fixed;
	top: 0;
	z-index: 1;

	.toolbar {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}

	.service-bg {
		position: relative;
		z-index: 2;
		margin-top: -7%;
		width: 100%;
		height: 183px;
		transform: skew(0, -8deg);
	}

	.user-area {
		position: relative;
		z-index: 2;
		margin-top: -90px;
	}
}

.h-124px{
  height: 124px;
}
</style>
