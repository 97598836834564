<template>
  <!-- 遊戲顯示 -->
  <v-row
    class="px-3 py-2"
  >
    <v-col
      v-for="game in games"
      :key="game._id"
      cols="6"
      class="px-1 py-1 p-relative"
    >
      <!-- 遊戲圖片 -->
      <v-img
        height="110"
        :src="baseImgUrl + game.game.image"
        class="rounded"
        :class="[{'cursor-pointer': game.status === '1', 'opacity-1': game.status === '0'}]"
        :alt="game.localized_name"
        @click="launchGame(game.game_code, game.status)"
      >
        <v-row
          slot="placeholder"
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </v-row>
      </v-img>

      <div
        v-if="game.status === '0'"
        class="p-absolute tag caption btnText--text primary rounded px-2 py-0"
      >
        {{ $t('global.inMaintenance') }}
      </div>

      <!-- 遊戲敘述 -->
      <div class="description d-flex align-center py-2 pl-3 pr-1">
        <!-- 遊戲名稱 -->
        <span
          class="description__name subtitle-2 title--text"
          :class="[{'opacity-1': game.status === '0'}]"
        >
          {{ game.localized_name }}
        </span>

        <v-spacer />
        <div class="description__icon">
          <!-- 已收藏 ♥ -->
          <v-btn
            v-if="game.favor === 'true' || game.favor === true"
            icon
            small
            @click="$emit('favorite-game-toggle', game.game_code)"
          >
            <Icon
              class="danger--text description__icon-favorite"
              data="@icon/favorite.svg"
              width="20"
              height="20"
            />
          </v-btn>

          <!-- 未收藏 ♡ -->
          <v-btn
            v-else-if="game.favor === 'false'"
            icon
            small
            @click="$emit('favorite-game-toggle', game.game_code)"
          >
            <Icon
              class="textfield--text description__icon-unfavorite"
              data="@icon/unfavorite.svg"
              width="20"
              height="20"
            />
          </v-btn>
        </div>
      </div>
    </v-col>

    <!--  Bottom LOADING  -->
    <v-col
      v-show="scrollBottom.progress"
      cols="12"
      class="text-center pb-0"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'

export default {
  props: {
    games: {
      type: Array,
      required: true,
    },
    scrollBottom: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
    }),
    ...mapGetters(['baseImgUrl']),
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'set_progress_status',
      'show_alert',
    ]),

    /**
     * 啟動遊戲 (成功)
     * 另開視窗啟動遊戲
     * @date 2021-06-15
     * @param {string} gameUrl 啟動連結
     */
    launchGameSuccess(gameUrl) {
      window.open(gameUrl)
    },

    launchGameFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得啟動遊戲連結
     * @date 2021-06-15
     * @param {string} gameCode 遊戲代碼
     */
    async launchGame(gameCode, gameStatus) {
      if (gameStatus === '0') return false

      this.set_progress_status(true)
      const gameUrl = await this.get_game_url({ gamecode: gameCode })
      this.set_progress_status(false)
      resStatus(gameUrl, this.launchGameSuccess, this.launchGameFail)
    },
  },
}
</script>

<style scoped>
.opacity-1 {
	opacity: .2;
}

.tag {
	top: 12px;
	right: 12px;
}
</style>
