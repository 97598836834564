<template>
  <div class="userInfo user card1 d-flex align-center px-4 p-relative">
    <!-- 使用者區塊 -->
    <div class="user-area">
      <!-- 如果尚未登入 -->
      <template v-if="(!isLogin)">
        <!-- 登入 -->
        <v-btn
          to="/login"
          outlined
          height="31"
          class="title--text text-center px-1 mr-3"
        >
          <span class="caption">
            {{ $t('subHeader.login') }}
          </span>
        </v-btn>

        <!-- 註冊 -->
        <v-btn
          v-if="registerStatus"
          to="/login/register"
          height="31"
          class="title--text text-center px-1 thaigi-gradient"
          :style="style === 'darkGD' ? '' : customGradientBgTypeA"
        >
          <span class="caption">
            {{ $t('subHeader.register') }}
          </span>
        </v-btn>
      </template>

      <!-- 如果登入 -->
      <template v-else>
        <div class="pl-0">
          <!-- 一般模式 -->
          <Icon
            data="@icon/user.svg"
            width="24"
            height="24"
            class="icon--text"
          />
          <span class="title--text caption ml-2">
            {{ userAccount }}
          </span>
        </div>
      </template>
    </div>

    <v-spacer />

    <!-- 使用者錢包 -->
    <div
      class="user-balance d-flex align-center"
    >
      <!-- 取得錢包 loading -->
      <div
        v-if="userBalanceProgressStatus || balanceLoading"
      >
        <v-progress-circular
          class="mr-4"
          indeterminate
          :width="2"
          :size="20"
          color="primary"
        />
      </div>

      <!-- (成功) 取得使用者錢包 -->
      <div
        v-else
        class="d-flex align-center"
      >
        <!-- 一鍵回收 -->
        <v-btn
          v-if="!overlay && isLogin"
          icon
          @click="retrieveAllWalletBalance"
        >
          <Icon
            data="@icon/user/walletItem/transfer.svg"
            width="23"
            height="20"
          />
        </v-btn>

        <span class="mr-1 title--text">{{ $t(`global.${currencySymbol}`) }}</span>
        <span class="text-h5 title--text">
          {{ userBalance }}</span>
      </div>
      <!-- 豬 pig icon -->
      <Icon
        data="@icon/moneyPig.svg"
        width="32"
        height="32"
        class="secondary--text ml-1"
        @click="fastTrade"
      />
    </div>

    <!-- OVERLAY -->
    <v-overlay
      :value="overlay"
      opacity=".8"
    />

    <div
      v-show="overlay"
      class="btn-grop p-absolute"
    >
      <div class="d-flex align-center">
        <!-- 取款 -->
        <v-btn
          to="user/withdrawal"
          text
          height="56"
          class="px-2"
        >
          <div class="d-flex flex-column align-center justify-center white--text caption font-weight-bold">
            <div class="iconGy rounded-circle w-h-32 d-flex justify-center align-center">
              <Icon
                data="@icon/user/walletItem/withdrawal.svg"
                width="23px"
                height="20px"
                class="black--text"
              />
            </div>
            {{ $t('global.withdraw') }}
          </div>
        </v-btn>

        <!-- 轉換 -->
        <v-btn
          to="user/transfer"
          text
          height="56"
          class="px-2"
        >
          <div class="d-flex flex-column align-center justify-center white--text caption font-weight-bold">
            <div class="iconGy rounded-circle w-h-32 d-flex justify-center align-center">
              <Icon
                data="@icon/user/walletItem/transfer.svg"
                width="23px"
                height="20px"
                class="black--text"
              />
            </div>
            {{ $t('global.transferBtn') }}
          </div>
        </v-btn>

        <!-- 存款 -->
        <v-btn
          text
          height="56"
          class="px-2"
          @click="goPageFunc('user/deposit')"
        >
          <div class="d-flex flex-column align-center justify-center white--text font-weight-bold subtitle-2">
            <div class="white rounded-circle w-h-35 d-flex justify-center align-center">
              <Icon
                data="@icon/user/walletItem/deposit.svg"
                class="black--text"
                width="32"
                height="25"
              />
            </div>
            {{ $t('global.deposit') }}
          </div>
        </v-btn>

        <!-- 關閉快捷 -->
        <v-btn
          icon
          class="mr-4"
          @click="overlay = false"
        >
          <div class="rounded-circle w-h-32 d-flex align-center justify-center">
            <Icon
              data="@icon/money-btn-close.svg"
              width="31"
              height="31"
              class="secondary--text"
            />
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { gsap } from 'gsap'
import { resStatus } from '../../../utils/resUtils'

export default {
  data: () => ({
    overlay: false,
    balanceLoading: false,
  }),

  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      userAccount: state => state.user.userData.member.account,
      userBalance: state => state.wallet.main,
      registerStatus: state => state.websiteData.func_switch.allow_register,
      userBalanceProgressStatus: state => state.userInfo.mainBalanceProgressStatus,
    }),

    ...mapGetters([
      'currencySymbol',
      'customGradientBgTypeA',
      'style',
      'isMycardDomainService',
    ]),
  },

  mounted() {
    if (this.isLogin) {
      this.SET_USERINFO_BALANCE_PROGRESS_STATUS(true)
      this.getUserBalance()
    }
  },

  methods: {
    ...mapActions(['get_user_balance', 'user_retrieve_all_wallet_balance', 'show_alert']),
    ...mapMutations(['SET_USERINFO_BALANCE_PROGRESS_STATUS']),

    async getUserBalance() {
      const walletcode = { walletcode: 'player_balance' }
      await this.get_user_balance(walletcode)
      this.SET_USERINFO_BALANCE_PROGRESS_STATUS(false)
    },

    fastTrade() {
      this.overlay = true
      this.btnAction()
    },

    btnAction() {
      // 設置動畫 gsap
      const btnArea = gsap.fromTo(
        '.btn-grop',
        // from
        {
          x: 244,
        },
        // to
        {
          duration: 0.2, // 運行時間
          x: 0,
          ease: 'none',
        })

      // 播放
      btnArea.play()
    },

    goPageFunc(routerPath) {
      this.$router.push({ path: routerPath })
    },

    async retrieveAllWalletBalanceSuccess() {
      await this.getUserBalance()
      this.balanceLoading = false
    },

    retrieveAllWalletBalanceFail() {
      this.show_alert({
        icon: 'fail',
      })
      this.balanceLoading = false
    },

    /**
     * 全部回收
     * - 操作 loading
     * @date 2021-04-14
     */
    async retrieveAllWalletBalance() {
      this.balanceLoading = true
      const res = await this.user_retrieve_all_wallet_balance()
      resStatus(res, this.retrieveAllWalletBalanceSuccess, this.retrieveAllWalletBalanceFail)
    },
  },
}
</script>

<style lang="scss" scoped>
.userInfo {
	height: 56px;
}

.btn-grop {
	right: 0;
	z-index: 99;
}

.w-h-32 {
	width: 32px;
	height: 32px;
}

.w-h-35 {
	width: 35px;
	height: 35px;
}

.thaigi-gradient {
  top: 0;
  right: 0;
  background-image: linear-gradient(110deg, #e6ce71 -5%, #bc954d 20%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}
</style>
