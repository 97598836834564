<template>
  <v-bottom-navigation
    fixed
    background-color="navBar"
    grow
    :height="navHeight"
  >
    <!-- 優惠 -->
    <v-btn
      color="navBar"
      :height="navHeight"
      to="/bonus"
    >
      <Icon
        v-if="$route.name === 'bonus'"
        data="@icon/bottomNav/bonus-active.svg"
        width="24px"
        height="24px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/bonus.svg"
        width="24px"
        height="24px"
        class="icon--text"
      />
    </v-btn>

    <!-- 客服 -->
    <v-btn
      color="navBar"
      class="service-btn p-relative"
      :height="navHeight"
      @click="showServiceArea"
    >
      <!-- to="/service" -->
      <Icon
        v-if="$route.name === 'service'"
        data="@icon/bottomNav/service-active.svg"
        width="24px"
        height="24px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/service.svg"
        width="24px"
        height="24px"
        class="icon--text"
      />

      <!-- Service Items -->
      <div
        v-show="serviceArea.status"
        class="service-items-area text-left"
      >
        <ul class="pl-0">
          <li
            v-for="(service, index) in serviceItems"
            :key="service + index"
            class="my-3 cursor-pointer"
            @click.stop="goService(service.name)"
          >
            <div v-if="service.name === 'Line' && showLineService || service.name === '24h'">
              <v-avatar
                color="white"
                size="40"
              >
                <img
                  class="pa-2"
                  :src="service.icon"
                  alt="line-service"
                >
              </v-avatar>
              <span class="caption ml-2 white--text">
                {{ service.text }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </v-btn>

    <!-- WEBSITE LOGO -->
    <router-link
      class="websiteLogo"
      to="/"
    >
      <div class="primary wh-56px rounded-circle d-flex justify-center align-center">
        <img
          :src="baseImgUrl + websiteLogo"
          :alt="websiteName"
          class="wh-38px"
        >
      </div>
    </router-link>

    <!-- 紀錄 -->
    <v-btn
      color="navBar"
      :height="navHeight"
      to="/record"
    >
      <Icon
        v-if="$route.name === 'record'"
        data="@icon/bottomNav/record-active.svg"
        width="24px"
        height="24px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/record.svg"
        width="24px"
        height="24px"
        class="icon--text"
      />
    </v-btn>

    <!-- 我的 -->
    <v-btn
      color="navBar"
      :height="navHeight"
      to="/user"
    >
      <Icon
        v-if="$route.name === 'user'"
        data="@icon/bottomNav/profile-active.svg"
        width="24px"
        height="24px"
        class="primary--text nav-active"
      />
      <Icon
        v-else
        data="@icon/bottomNav/profile.svg"
        width="24px"
        height="24px"
        class="icon--text"
      />
    </v-btn>

    <v-overlay
      :value="serviceArea.status"
      opacity=".9"
    />
  </v-bottom-navigation>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import service24HIcon from '@/assets/icons/24h_service.webp'
import lineServiceIcon from '@/assets/icons/live_service.webp'

export default {
  data: () => ({
    // 目前點擊的項目
    navActive: null,
    lineServiceIcon,
    service24HIcon,

    // serviceItemsArea
    serviceArea: {
      status: false,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'currentDevice']),
    ...mapState({
      showLineService: state => state.websiteData.cs_settings.cs_line_enable,
      lineServiceUrl: state => state.websiteData.cs_settings.line_url,
    }),
    navHeight() {
      return this.currentDevice === 'ios' ? 72 : 56
    },

    serviceItems() {
      return {
        serviceLine: {
          icon: this.lineServiceIcon,
          text: 'LINE',
          name: 'Line',
          url: this.lineServiceUrl,
        },
        // 暫時隱藏 24客服
        // service24h: {
        //   icon: this.service24HIcon,
        //   text: this.$t('service.24hService'),
        //   name: '24h',
        //   url: '/service',
        // },
      }
    },
  },

  mounted() {
    this.ifServiceOverlayClick()
  },

  methods: {
    showServiceArea() {
      this.serviceArea.status = true
    },

    ifServiceOverlayClick() {
      document.onmouseup = (e) => {
        if (e.target.classList.value === 'v-overlay__scrim') {
          this.serviceArea.status = false
        }
      }
    },

    serviceLine() {
      location.href = this.serviceItems.serviceLine.url
    },
    service24h() {
      if (this.$route.path === '/service') return false
      this.$router.push({ path: this.serviceItems.service24h.url })
    },

    goService(goType) {
      this[`service${goType}`]()
      this.serviceArea.status = false
    },
  },
}
</script>

<style lang="scss" scoped>
.websiteLogo {
	transform: translateY(-10px);
}
.wh-56px {
  height: 56px;
  width: 56px;
}
.wh-38px {
  height: 38px;
  width: 38px;
}

.service-items-area {
  position: absolute;
  bottom: 72px;
  left: 0;
  z-index: 99;
  > ul {
    list-style: none;
  }
}
</style>
