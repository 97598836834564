<template>
  <div class="d-flex align-center justify-center">
    <div class="text-center">
      <v-img
        width="120"
        src="../../assets/image/nodata.png"
      />
      <span class="hint--text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped></style>
