<template>
  <div
    id="home"
    :class="[homePaddingTopClass, currentDevice === 'ios' ? 'pb-72' : 'pb-56']"
  >
    <!-- SAFARI 禁開 -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="170"
      @update-dialog="setSafariDialogStatus"
    >
      <p
        slot="text"
        class="px-6 mb-0"
      >
        {{
          $t('flashMessage.browserSetting', {browser: device})
        }}
      </p>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 最新消息 dialog -->
    <v-dialog
      v-model="newsDialog"
      overlay-opacity=".8"
      :max-width="280"
    >
      <!-- 關閉 dialog btn -->
      <div class="text-right mb-3">
        <v-btn
          icon
          outlined
          dark
          max-height="24"
          max-width="24"
          class="close-dialog-icon mr-1"
          @click="newsDialog = false"
        >
          <v-icon
            size="18"
            color="white"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card
        class="bg rounded-lg"
        height="356"
      >
        <v-container
          class="ma-0 h-100 p-relative"
          fluid
        >
          <v-row dense>
            <!-- 上一則 -->
            <v-col
              cols="12"
              class="d-flex py-0"
            >
              <div
                v-show="activeNews > 0"
                class="prev"
              >
                <v-btn
                  class="card2"
                  @click="prevNews"
                >
                  <Icon
                    data="@icon/double-arrows-left.svg"
                    width="20"
                    height="20"
                    class="primary--text mr-1"
                  />
                </v-btn>
              </div>

              <v-spacer />

              <!-- 下一則 -->
              <div class="next">
                <v-btn
                  class="card2"
                  @click="nextNews"
                >
                  <span class="mr-1 caption primary--text">
                    {{ $t('global.nextItem') }}
                  </span>
                  <Icon
                    data="@icon/double-arrows-right.svg"
                    width="20"
                    height="20"
                    class="primary--text mr-1"
                  />
                </v-btn>
              </div>
            </v-col>

            <v-col
              cols="12"
              class="py-0 px-6"
            >
              <div class="mt-3 text-center">
                <Icon
                  data="@icon/bell.svg"
                  width="24"
                  height="24"
                  class="secondary--text mr-1"
                />
              </div>

              <div class="news-title title--text subtitle-1 font-weight-medium mt-5">
                {{ news[activeNews].title.length > 27 ?news[activeNews].title.slice(0, 27) + '...' :
                  news[activeNews].title }}
              </div>

              <div class="news-contnet caption text-text mt-4">
                {{ news[activeNews].content.length > 170 ? news[activeNews].content.slice(0, 170) + '...'
                  :news[activeNews].content }}
              </div>
            </v-col>
          </v-row>

          <!-- 不再顯示 -->
          <div class="p-absolute not-remember">
            <v-checkbox
              v-model="notRememberMe"
              class="primary--text"
              dense
            >
              <div
                slot="label"
                class="primary--text caption"
              >
                {{ $t('global.DontShowAgainToday') }}
              </div>
            </v-checkbox>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- app 無法開啟 dialog -->
    <v-dialog
      v-model="cantOpenAppDialog.status"
      class="cant-open-app-dialog"
      overlay-opacity=".4"
      :max-width="280"
    >
      <!-- 關閉 dialog btn -->
      <div class="text-right mb-3">
        <v-btn
          icon
          outlined
          dark
          max-height="24"
          max-width="24"
          class="close-dialog-icon mr-1"
          @click="cantOpenAppDialog.status = false"
        >
          <v-icon
            size="18"
            color="white"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <!-- 長按保存圖片 -->
      <div class="text-center">
        <v-snackbar
          v-model="snackbar"
          centered
          class="snackbar"
          :timeout="timeout"
        >
          {{ $t('service.longPressThePictureToSave') }}
        </v-snackbar>
      </div>

      <v-card class="bg rounded-lg p-relative">
        <v-container
          class="ma-0 h-100 p-relative"
          fluid
        >
          <v-row>
            <v-col
              cols="12"
              class="pa-0 p-relative"
            >
              <img :src="baseImgUrl + csProfile.backup_domains_image">

              <!-- ACTIONS -->
              <div class="d-flex actions p-absolute w-100 px-6">
                <div class="flex-grow-1 mr-2">
                  <v-btn
                    block
                    class="card2"
                    @click="snackbar = true"
                  >
                    {{ $t('global.save') }}
                  </v-btn>
                </div>

                <div class="flex-grow-1">
                  <v-btn
                    block
                    color="primary"
                    class="btnText--text"
                    @click="copyText('copyText')"
                  >
                    {{ $t('global.copy') }}
                  </v-btn>

                  <!-- 復制文字 (hidden) -->
                  <input
                    id="copyText"
                    type="text"
                    :value="csProfile.backup_domains_text"
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- fixed 區塊 -->
    <div class="fixed-area bg">
      <DownloadApp
        v-show="downloadAppDsp"
        @close-download-app="closeDownloadApp"
      />
      <UserInfo />
      <Marquee />
      <FifaWorldCup v-show="worldCupAreaDisplay" />
    </div>

    <component
      :is="item.block"
      v-for="(item, index) in layoutArea"
      :key="item.block + `-${index}`"
      :data="item"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mobileDevice } from '../../utils/browserCheck'
import { timezone } from '@/utils/day'
import { setCookie, getCookie } from '../../utils/cookie'
import { copy } from '@/utils/copy'

import UserInfo from './components/UserInfo'
import Marquee from './components/Marquee.vue'
import DownloadApp from './components/DownloadApp.vue'
import BootomNav from '@/components/base/BottomNav'
import Dialog from '@/components/dialog/index.vue'

// forEach Components
import AllHomeAreaComponents from './components/index.js'
import { resStatus } from '../../utils/resUtils'
import FifaWorldCup from './components/FifaWorldCup'

export default {
  components: {
    Dialog,
    DownloadApp,
    UserInfo,
    Marquee,
    BootomNav,
    FifaWorldCup,
    ...AllHomeAreaComponents,
  },

  data: () => ({
    device: null,
    dialog: {
      status: false,
    },

    form: {
      timezone: '',
      page: 1,
    },

    // download APP dispaly
    downloadAppDsp: true,

    news: [
      {
        content: '--',
        start_datetime: '--',
        title: '--',
      },
    ],
    activeNews: 0,
    newsDialog: false,

    notRememberMe: false,

    // 無法開啟 app dialog 狀態
    cantOpenAppDialog: {
      status: false, // 顯示狀態
    },

    // 長按保存到本地 popup
    snackbar: false,
    timeout: 2000,
  }),

  computed: {
    ...mapGetters(['websiteName', 'baseImgUrl', 'currentDevice']),

    ...mapState({
      layoutArea: state => state.indexPageLayoutArea,
      isLogin: state => state.user.isLogin,
      csProfile: state => state.websiteData.cs_profile,
      // 是否顯示世界盃賽程區塊
      worldCupAreaDisplay: state => state.websiteData.worldcup_info?.worldcup_enable,
    }),

    allDevice() {
      return navigator.userAgent
    },

    dontShowAginCookie() {
      return getCookie('dontShowNewsToday') === 'true'
    },
    homePaddingTopClass() {
      if (this.downloadAppDsp && !this.worldCupAreaDisplay) return 'pt-144'
      else if (!this.downloadAppDsp && this.worldCupAreaDisplay) return 'pt-132'
      else if (this.downloadAppDsp && this.worldCupAreaDisplay) return 'pt-188'
      else return 'pt-88'
    },
  },

  watch: {
    device(isNotBlankWindowBrowser) {
      if (!isNotBlankWindowBrowser) return false
      if (sessionStorage.getItem('safariDialog')) return false
      this.dialog.status = true
    },

    /**
     * 無法安裝 app 彈窗 狀態監聽
     * 若關閉 -> 儲存 cookie (今日不再顯示)
     * @date 2021-10-25
     * @param {boolean} newStatus 最新狀態
     */
    'cantOpenAppDialog.status'(newStatus) {
      if (!newStatus) this.dialogNotDispalyToday('cantOpenAppDialogStatus')
    },

    /**
     * 最新消息 彈窗 狀態監聽
     * 若關閉 且 今日不再顯示勾選 -> set cookie
     * @date 2021-10-25
     * @param {boolean} newStatus 最新狀態
     */
    newsDialog(newStatus) {
      if (!newStatus && this.notRememberMe) this.dialogNotDispalyToday('dontShowNewsToday')
    },

    'dialog.status'(newStatus) {
      if (newStatus) return false
      sessionStorage.setItem('safariDialog', 'false')
    },
  },

  mounted() {
    this.device = mobileDevice()

    this.checkDwonloadDspSession()

    this.form.timezone = timezone()

    if (this.isLogin) this.getNewsData()

    // webview 不顯示下載 app
    this.$log(`webview: ${!this.checkWebView()}`)
    this.downloadAppDsp = this.checkWebView()
  },

  methods: {
    ...mapActions(['get_news_api', 'show_alert']),

    checkWebView() {
      const webview = navigator.userAgent.indexOf('platform')
      const ios = navigator.userAgent.indexOf('iOS')
      const android = navigator.userAgent.indexOf('android')

      if (webview > -1 && ios > -1) return false
      if (webview > -1 && android > -1) return false
      else return true
    },

    setSafariDialogStatus(newStatus) {
      this.dialog.status = newStatus
    },

    /**
     * 立即下載 區塊顯示 設置
     * @date 2021-08-27
     */
    checkDwonloadDspSession() {
      const downloadAppDspStatus = sessionStorage.getItem('downloadAppDsp')

      if (!downloadAppDspStatus) sessionStorage.setItem('downloadAppDsp', JSON.stringify(this.downloadAppDsp))
      else this.downloadAppDsp = JSON.parse(downloadAppDspStatus)
    },

    /**
     * 即下載 區塊顯示 (關閉)
     * @date 2021-08-27
     */
    closeDownloadApp() {
      this.downloadAppDsp = false
      sessionStorage.setItem('downloadAppDsp', JSON.stringify(this.downloadAppDsp))
    },

    /**
     * 下載應用程式 && 復製 website namne
     * @date 2021-06-21
     * @param {string} appUrl app 下載連結
     */
    downloadApp(appUrl) {
      this.copyNumber('websiteName')
      location.href = appUrl
    },

    getNewsDataSuccess(data) {
      const news = data.news.data

      //  如果有圖片，才會顯示 彈窗 @備用 url 彈窗
      if (this.csProfile.backup_domains_image && !getCookie('cantOpenAppDialogStatus')) this.cantOpenAppDialog.status = true

      if (news.length > 0) this.news = news
      if (news.length > 0 && !getCookie('dontShowNewsToday')) this.newsDialog = true
    },

    getNewsDataFail() {},

    /**
     * 取得最新消息
     * @date 2021-10-13
     */
    async getNewsData() {
      const res = await this.get_news_api(this.form)
      resStatus(res, this.getNewsDataSuccess, this.getNewsDataFail)
    },

    /**
     * 上一則
     * @date 2021-10-01
     */
    prevNews() {
      if (this.activeNews === 0) return false
      this.activeNews--
    },

    /**
     * 下一則
     * @date 2021-10-01
     */
    nextNews() {
      if (this.activeNews + 1 === this.news.length) return false
      this.activeNews++
    },

    /**
     * 復制文字功能
     * @date 2021-04-07
     * @param {string} id 需要復製的文字 id
     */
    copyText(id) {
      try {
        copy(id)
        this.show_alert({
          icon: 'done',
        })
      } catch {
        this.show_alert({
          icon: 'fail',
        })
      }
    },

    /**
     * 今日不再顯示 (當日晚上 23:59:59)
     * @date 2021-10-22
     */
    dialogNotDispalyToday(item) {
      const nowDate = this.$day()
      document.cookie = `${item}=false; expires=${new Date(nowDate.year(), nowDate.month(), nowDate.date(), 23, 59, 59).toGMTString()}`
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-area {
	position: fixed;
	top: 0;
	z-index: 2;
	width: 100%;
}

.pt-144 {
	padding-top: 144px;
}
.pt-188 {
  padding-top: 188px;
}

.pt-88 {
	padding-top: 88px;
}
.pt-132 {
  padding-top: 132px;
}

.not-remember {
	bottom: 0;
	left: 20px;
}

#copyText {
	position: absolute;
	height: 0;
}

img {
	width: 100%;
	object-fit: cover;
}

.actions {
	bottom: 16px;
	left: 0;
}
</style>
