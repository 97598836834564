import request from '@/api/request'

/*
  API 錢包
  錢包相關的 API
    - 取得錢包列表
    - 取得單一錢包餘額
    - 切換錢包模式
    - 中心錢包的一鍵回收
    - 遊戲錢包的一鍵轉入
    - 中心錢包與遊戲錢包之間的轉帳
*/

/* 取得錢包列表 */
export const getWalletList = (data) =>
  request.post('api/v1/member/wallets', data)

/* 取得 中心/鎖定 錢包金額 */
export const getWalletBalance = (data) =>
  request.post('api/v1/member/balance', data)

/* 切換錢包模式 */
export const changeWalletMode = (data) =>
  request.post('/api/v1/member/switch-wallettype', data)

/* 中心錢包的一鍵回收 */
export const retrieveGameWallets = (data) =>
  request.post('/api/v1/member/retrieve-game-wallets', data)

/* 遊戲錢包的一鍵轉入 */
export const allTransferInGameWallets = (data) =>
  request.post('/api/v1/member/onetime-transferin', data)

/* 中心錢包與遊戲錢包之間的轉帳 */
export const walletTransfer = (data) =>
  request.post('/api/v1/member/wallet-transfer', data)
