<template>
  <!-- 手機 app -->
  <div class="pt-56">
    <!-- TOOLBAR -->
    <Toolbar>
      <v-btn
        slot="btn"
        icon
        color="success"
        to="/service"
      >
        <Icon
          data="@icon/bottomNav/service.svg"
          width="24px"
          height="24px"
          class="icon--text"
        />
      </v-btn>
    </Toolbar>

    <v-container>
      <v-row class="px-1">
        <v-col
          cols="12"
          class="py-0 text-center"
        >
          <!-- 標題 -->
          <h2 class="secondary--text text-center mt-7 mb-6">
            {{ $t('app.title') }}
          </h2>
          <!-- 安裝教程 -->
          <v-btn
            to="/app/tutorial"
            color="primary"
          >
            <span class="title--text">
              {{ $t('app.installationTutorial') }}
            </span>
          </v-btn>
        </v-col>

        <!-- 下載 app -->
        <v-col
          cols="12"
          class="text-center download-app"
        >
          <v-img
            height="290"
            class="mt-4"
            :src="baseImgUrl + appDemoImg"
          />
          <div class="btn relative-y-3">
            <!-- 橫向 app 下載 -->
            <v-btn
              color="primary"
              class="mr-3"
              @click="downloadApp"
            >
              {{ $t('global.landscape') + ' APP' }}
            </v-btn>

            <!--  ios -->
            <!-- <v-btn
              color="primary"
              class="mr-3"
              @click="downloadApp('ios')"
            >
              <v-icon class="mr-2 icon--text">
                mdi-apple
              </v-icon>
              {{ $t('app.ios') }}
            </v-btn> -->

            <!-- 網站名稱 供復製 -->
            <input
              id="recommendCode"
              type="text"
              :value="recommendCode"
            >

            <!--  android -->
            <!-- <v-btn
              color="primary"
              @click="downloadApp('android')"
            >
              <Icon
                data="@icon/android.svg"
                width="18"
                height="18"
                class="icon--text mr-2"
              />
              {{ $t('app.android') }}
            </v-btn> -->
          </div>
        </v-col>
      </v-row>

      <!-- feature -->
      <v-row class="px-10">
        <!-- 特點項目 @for -->
        <v-col
          v-for="(item,index) in feature"
          :key="index"
          cols="6"
          class="d-flex align-center justify-start flex-column subtitle-2 text-center title--text"
        >
          <Icon
            :data="featureIcon[index]"
            width="38"
            height="38"
            class="secondary--text mb-4"
          />
          <div class="feature__title mb-1">
            {{ Object.keys(item)[0] }}
          </div>
          <div class="feature__description caption">
            {{ Object.values(item)[0] }}
          </div>
        </v-col>
        <v-col cols="6" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { copyNumber } from '@/utils/copy'
import { resStatus } from '../../utils/resUtils'
import { deviceCheck } from '../../utils/device'

import Toolbar from '../../components/base/Toolbar.vue'
import fast from '@/assets/icons/app/fast.svg'
import privateIcon from '@/assets/icons/app/private.svg'
import safe from '@/assets/icons/app/safe.svg'
import stable from '@/assets/icons/app/stable.svg'

export default {
  components: { Toolbar },

  data: () => ({
    featureIcon: [
      fast,
      stable,
      safe,
      privateIcon,
    ],
  }),

  computed: {
    ...mapState({
      mobileData: state => state.websiteData.mobile,
      recommendCode: state => state.user.userData.recommend_code,
      appDemoImg: state => state.websiteData.plan.agent_image_2,
      serviceUrl: state => state.serviceUrl,
    }),
    ...mapGetters(['websiteName', 'baseImgUrl']),

    // 特點項目
    feature() {
      return [
        // fast
        { [this.$t('app.sectionTitle1')]: this.$t('app.sectionDesc1') },
        // stable
        { [this.$t('app.sectionTitle2')]: this.$t('app.sectionDesc2') },
        // safe
        { [this.$t('app.sectionTitle3')]: this.$t('app.sectionDesc3') },
        // privateIcon
        { [this.$t('app.sectionTitle4')]: this.$t('app.sectionDesc4') },
      ]
    },

    device() {
      return deviceCheck()
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('menu.app'))
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'set_website',
      'get_app_download_url',
      'show_alert',
    ]),

    goDownloadPage(downloadUrl) {
      if (!downloadUrl) {
        this.show_alert({
          icon: 'fail',
        })
        return false
      }
      this.copyNumber('recommendCode')
      location.href = downloadUrl
    },

    /**
     * 下載應用程式 && 復製 推薦碼
     * @date 2021-06-21
     * @param {string} appUrl app 下載連結
     */
    async downloadApp() {
      const website = await this.set_website()
      const {
        data: {
          android_landscape_update_url,
          app_landscape_download_url,
        },
      } = await this.get_app_download_url({ website })

      this.device === 'android' ? this.goDownloadPage(android_landscape_update_url) : this.goDownloadPage(app_landscape_download_url)
    },

    /**
     * 復製文字
     * @date 2021-06-01
     * @param {string} id 需要復製文字的 input id
     */
    copyNumber(id) {
      copyNumber(id)
    },
  },
}
</script>

<style lang="scss" scoped>
.download-app {
	position: relative;

	.relative-y-3 {
		position: absolute;
		bottom: 28px;
		left: 0;
		width: 100%;
	}
}

#recommendCode {
	position: absolute;
	height: 0;
}
</style>
