import {
  getVendorGameListAPI,
  getVendorLogoAPI,
  getVendorHotGameListAPI,
  getVendorNewGameListAPI,
  getVendorFavorGameListAPI,
  editFavorGameAPI,
  getGameLaunchUrlAPI,
  getGameLaunchUrlByDeviceAPI,
} from '@/api'

const state = () => ({
  value: 'slot',
})

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * 取得游戲商類別_游戲列表 (與相關資料)
   * @date 2021-06-02
   * @param {object} {commit} mutations
   * @param {string} type 游戲商類別
   * @returns {object} 游戲商下所有游戲
   */
  async get_game_brands({ commit }, type) {
    const res = await getVendorLogoAPI(type)
    return res
  },

  /**
   * 取得 vendor 底下 (所有) 游戲列表
   * @date 2021-06-02
   * @param {any} vendorData
   * @returns {object} 所有 game_code 資料
   */
  async get_vendor_list({ commit }, vendorData) {
    const res = await getVendorGameListAPI(vendorData)
    return res
  },

  /**
   * 取得 vendor 底下 (熱門) 遊戲列表
   * @date 2021-06-03
   * @param {object} vendorData
   * @returns {object}
   */
  async get_vendor_hot_list({ dispatch }, vendorData) {
    const res = await getVendorHotGameListAPI(vendorData)
    return res
  },

  /**
   * 取得 vendor 底下 (最新) 遊戲列表
   * @date 2021-06-03
   * @param {object} vendorData
   * @returns {object}
   */
  async get_vendor_new_list({ dispatch }, vendorData) {
    const res = await getVendorNewGameListAPI(vendorData)
    return res
  },

  /**
   * 取得 vendor 底下 (最愛/收藏) 遊戲列表
   * @date 2021-06-03
   * @param {any} vendorData
   * @returns {any}
   */
  async get_vendor_favorite_list(context, vendorData) {
    const res = await getVendorFavorGameListAPI(vendorData)
    return res
  },

  /**
   * 使用者 新增/移除 套愛(收藏) 遊戲
   * @date 2021-06-07
   * @param {object} context
   * @param {object} gameCode 遊戲代碼
   * @returns {object} 最愛狀態
   */
  async vendor_game_favorite_toggle(context, gameCode) {
    const favoriteStatus = await editFavorGameAPI(gameCode)
    return favoriteStatus
  },

  /**
   * 取得啟動遊戲連結
   * @date 2021-06-15
   * @param {object} context STORE
   * @param {String} gameCode 遊戲代號
   * @returns {object} 遊戲連結
   */
  async get_game_url(context, gameCode) {
    const gameUrl = await getGameLaunchUrlAPI(gameCode)
    return gameUrl
  },

  /**
   * 取得遊戲連結 (by device)
   * @date 2021-09-30
   * @param {object} context STORE
   * @param {String} gameCode 遊戲代號
   * @returns {object} 遊戲連結
   */
  async get_game_url_by_device(context, gameCode) {
    const gameUrl = await getGameLaunchUrlByDeviceAPI(gameCode)
    return gameUrl
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
