<template>
  <v-app-bar
    fixed
    flat
    class="pa-0 ma-0"
    :color="color || bgClr"
  >
    <!-- 回上一頁 -->
    <v-btn
      icon
      class="pa-0"
      @click="goBack"
    >
      <Icon
        data="@icon/toolbar/arrow_left.svg"
        width="16px"
        height="16px"
        class="icon--text"
      />
    </v-btn>

    <!-- 標題文字 -->
    <v-toolbar-title class="title--text font-weight-medium">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />

    <!-- button -->
    <slot name="btn" />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapState({
      title: state => state.toolbar.title,
    }),
    ...mapGetters(['theme']),
    bgClr() {
      return this.$vuetify.theme.themes[this.theme]?.navBar
    },
  },

  destroyed() {
    this.set_toolbar_title('')
  },

  methods: {
    ...mapActions(['set_toolbar_title']),
    goBack() {
      this.$log(`location: ${window.location}`)
      if (this.$route.name === 'launchGame') {
        this.$route.query.redirectUrl
          ? this.$router.replace({ path: this.$route.query.redirectUrl })
          : this.goBackIfNoHistory()

        return false
      }
      this.goBackIfNoHistory()
    },

    goBackIfNoHistory() {
      if (window.history.length < 2) return this.$router.push({ path: '/' })
      if (this.$route.name === 'slot') return this.$router.go(-2)
      this.$router.go(-1)
    },
  },

}
</script>

<style lang="scss" scoped></style>
