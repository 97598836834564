import request from '@/api/request'
/*
  API 使用者
  使用者相關的 API
    - 登入
    - 註冊
    - 登出
    - 修改密碼
    - 取得玩家基本資料
*/

/* 會員登入 */
export const userLogin = data => request.post('api/v1/auth/login', data)

// 會員資料 刷新
export const userDataRefresh = data => request.post('api/v1/auth/refresh')

/* 會員登出 */
export const userLogout = () => request.post('api/v1/auth/logout')

/* 會員註冊 (沒有推薦碼) */
export const userRegister = data => request.post('api/v1/auth/register', data)

/* 會員註冊 (有推薦碼) url + query */
export const userRecommand = data =>
  request.post(`api/v1/auth/register?recommand=${data.recommandCode}`, data)

/* 修改密碼 */
export const editUserPassword = data =>
  request.post('api/v1/member/password', data)

/* 取得玩家VIP資料 */
export const getUserVipData = () => request.get('/api/v1/member/vipinfo')

/* 取得玩家基本資料 */
export const getUserInfo = () => request.get('/api/v1/member/baseinfo')

/* 修改玩家基本資料 */
export const editUserInfo = data =>
  request.post('/api/v1/member/baseinfo', data)

/* 取得玩家資金明細 */
export const getUserWalletDetail = data =>
  request.post('/api/v1/member/wallet-detail', data)

// 發送 email 驗証
export const sendEmailValidateCode = email =>
  request.post('/api/v1/member/email-verify/send', email)

// 驗証 email 驗証碼
export const validateEmailValidateCode = emailAndValidateCode =>
  request.post('/api/v1/member/email-verify/validate', emailAndValidateCode)

// 使用者修改密碼 (忘記密碼(驗證並修改)
export const resetUserPasswordByNoLogin = data => request.post('/api/v1/member/forget-password', data)

// Google SignIn
export const googleAuthLogin = (data) => request.post('/api/v1/auth/oauth/google/login', data)

// Apple SignIn
export const appleAuthLogin = appleIdToken => request.post('/api/v1/auth/oauth/apple/login', appleIdToken)

export const lineAuthLogin = data => request.post('/api/v1/auth/oauth/line/login', data)
