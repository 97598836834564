// 彈窗相關
import {
  SET_ALERT_DATA,
  SET_ALERT_STATUS,
  SET_PROGRESS_STATUS,

} from '../mutation-types'

const state = () => ({
  alert: {
    status: false,
    icon: '',
    text: '--',
  },
  progress: {
    status: false,
  },
})

const mutations = {
  [SET_ALERT_DATA](state, alertData) {
    state.alert.icon = alertData.icon
    state.alert.text = alertData.text || ' '
  },
  [SET_ALERT_STATUS](state, boolean) {
    state.alert.status = boolean
  },
  [SET_PROGRESS_STATUS](state, status) {
    state.progress.status = status
  },

}

const actions = {
  close_alert({ commit }) {
    const alertData = {
      icon: '',
      text: '',
    }
    commit('SET_ALERT_DATA', alertData)
    commit('SET_ALERT_STATUS', false)
  },

  set_alert({ commit, dispatch }, alertData) {
    commit('SET_ALERT_DATA', alertData)
    commit('SET_ALERT_STATUS', true)

    setTimeout(() => {
      dispatch('close_alert')
    }, 1000)
  },

  show_alert({ state, dispatch }, alertData) {
    if (!state.alert.status) {
      dispatch('set_alert', alertData)
    } else {
      setTimeout(() => {
        dispatch('set_alert', alertData)
      }, 900)
    }
  },

  set_progress_status({ commit }, status) {
    commit('SET_PROGRESS_STATUS', status)
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
