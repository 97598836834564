<template>
  <!-- 503 網頁維護 -->
  <div class="vh-100 d-flex flex-column justify-center align-center px-12">
    <div class="text-center">
      <img
        width="88"
        :src="require('@/assets/image/503.png')"
        :alt="503"
      >
      <div class="hint--text">
        {{ errorText }}
      </div>
    </div>

    <v-btn
      color="primary"
      class="mt-10"
      @click="toServicePage"
    >
      <Icon
        data="@icon/bottomNav/service.svg"
        width="24px"
        height="24px"
        class="btnText--text mr-2"
      />
      <span
        class="btnText--text "
      >{{ $t('flashMessage.contactCustomerService') }}</span>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      serviceUrl: state => state.serviceUrl,
    }),
    /**
     * 錯誤信息文字
     * @date 2021-09-08
     */
    errorText() {
      return this.$t('global.inMaintenance')
    },
  },

  methods: {
    ...mapActions(['get_service_url']),

    async toServicePage() {
      await this.get_service_url()
      window.open(this.serviceUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped></style>
