import request from '@/api/request'

/*
  取得手機驗證碼
*/
export function getMobileSms(data) {
  return request({
    url: 'api/v1/member/sms/send',
    method: 'post',
    data,
  })
}

/*
  驗證 手機驗証碼
*/
export function verifyMobileSms(data) {
  return request({
    url: 'api/v1/member/sms/validate',
    method: 'post',
    data,
  })
}
