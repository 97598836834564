<template>
  <!-- server 錯誤 500 -->
  <div class="vh-100 d-flex flex-column justify-center align-center px-12">
    <div class="text-center">
      <Icon
        data="@icon/alert/error.svg"
        width="60"
        height="60"
        color="red"
      />
      <h1>500</h1>
      <span>Internal Server Error</span>
    </div>

    <div
      class="text-center caption my-5"
    >
      {{ errorText }}
      <span
        class="primary--text cursor-pointer"
        @click="toServicePage"
      >{{ $t('flashMessage.contactCustomerService') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      serviceUrl: state => state.serviceUrl,
    }),
    /**
     * 錯誤信息文字
     * @date 2021-09-08
     */
    errorText() {
      return this.$t('flashMessage.500')
    },
  },

  methods: {
    ...mapActions(['get_service_url']),

    async toServicePage() {
      await this.get_service_url()
      window.open(this.serviceUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped></style>
