import Vue from '@/main.js'

/**
 * 設定 深淺模式 && 色彩包
 * @date 2021-03-04
 * @param {any} theme='light' 模式
 * @param {any} style='sport' 色彩包
 */
export default (
  theme = process.env.VUE_APP_THEME,
  style = process.env.VUE_APP_TYPE,
) => {
  const colors = require(`../style/type/${theme}/${style}.json`)
  const staticColors = require(`../style/type/${theme}/static.json`)

  // 設置 (深/淺) 模式
  Vue.$vuetify.theme[theme] = true

  /**
   * 設置 (深/淺)模式 「靜態顏色」
   * @date 2021-04-12
   * @param {object}} staticColors 指定模式，通用的顏色
   */
  Object.keys(staticColors).forEach(colorVariableName => {
    Vue.$vuetify.theme.themes[theme][colorVariableName] =
      staticColors[colorVariableName]
  })

  /**
   * 設置 主題色 「動態顏色」
   * @date 2021-04-12
   * @param {object} colorVariableName
   */
  Object.keys(colors).forEach(colorVariableName => {
    Vue.$vuetify.theme.themes[theme][colorVariableName] =
      colors[colorVariableName]
  })
}
