<template>
  <div
    class="bonusPage"
    :class="currentDevice === 'ios' ? 'pb-72' : 'pb-56'"
  >
    <v-container>
      <v-row class="">
        <v-col
          cols="12"
          class="p-fixed pb-0 px-0 bg z-index-4"
        >
          <h1 class="title--text text-h6 pl-6">
            {{ $t('menu.preferentialActivity') }}
          </h1>

          <v-tabs
            background-color="bg"
            center-active
            show-arrows
          >
            <v-tab
              class="subtitle-1 font-weight-medium"
              @click="changeBonusNewsType('all')"
            >
              {{ $t('global.all') + $t('bottomNav.preferentialActivity') }}
            </v-tab>

            <v-tab
              v-for="item in tab"
              :key="item._id"
              class="subtitle-1 font-weight-medium"
              @click="changeBonusNewsType(item.code)"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row
        v-if="bonusNews.length === 0"
        class="ma-0 pt-100"
        align="center"
        justify="center"
      >
        <v-col>
          <Nodata :text="$t('global.noRecords')" />
        </v-col>
      </v-row>

      <v-row
        v-else
        class="pt-100 px-1"
      >
        <v-col
          v-for="item in bonusNews"
          :key="item._id"
          cols="12"
          class="py-1 "
        >
          <div class="p-relative">
            <router-link
              class="text-decoration-none bonus z-index-3"
              :to="{
                name: 'bonusItem',
                params: {
                  id: item.promo_id,
                },
              }"
            >
              <div class="bonus__item-bg  p-absolute rounded z-index-1" />

              <div class="bonus__more white--text d-flex p-absolute z-index-3">
                <div class="caption bonus__description">
                  {{ $t('global.more') }}
                </div>
                <v-icon color="white">
                  mdi-chevron-right
                </v-icon>
              </div>

              <v-img
                min-height="104"
                class="rounded"
                :src="baseImgUrl + item.banner"
                :alt="item.title"
              >
                <v-row
                  slot="placeholder"
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </v-row>
              </v-img>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import Nodata from '@/components/base/Nodata'
import { timezone } from '@/utils/day'

export default {
  components: { Nodata },

  data: () => ({
    form: {
      timezone: '',
      page: 1,
      bonus_type: 'all',
    },

    resLastPage: null,

    tab: [],
    bonusNews: {},
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'currentDevice']),
  },

  created() {
    this.form.timezone = timezone()
    this.getBonusNews()
  },

  methods: {
    ...mapActions(['get_bonus_news']),
    ...mapMutations(['SET_BONUS_NEWS']),

    changeBonusNewsType(bonus_type) {
      this.form.page = 1
      this.form.bonus_type = bonus_type
      this.getBonusNews(this.form)
    },

    /**
     * 取得優惠活動 (成功)
     * @date 2021-07-27
     * @param {object} data
     */
    getBonusNewsSuccess(data) {
      // 如果是第二次之後取得，將資料塞入後面
      if (this.form.page !== 1) {
        this.bonusNews = { ...this.bonusNews, ...data.bonus_news.data }
        this.$scrollToTop()
        return false
      }

      // 第一頁取得方式
      this.tab = data.bonus_types
      this.bonusNews = data.bonus_news.data
      this.$scrollToTop()

      this.resLastPage = data.bonus_news.last_page

      // 如果除了一頁之外的資料，再另取
      if (this.resLastPage > this.form.page) {
        this.getOtherBonusNews(this.resLastPage)
      }
    },

    getBonusNewsFail() {
      // 如果優惠活動 status === 0
    },

    /**
     * 取得優惠活動
     * @date 2021-07-27
     */
    async getBonusNews() {
      const res = await this.get_bonus_news(this.form)

      resStatus(res, this.getBonusNewsSuccess, this.getBonusNewsFail)
    },

    /**
     * 取得其它頁活動資料
     * @date 2021-07-27
     * @param {number} lastPage 最後一頁
     */
    getOtherBonusNews(lastPage) {
      this.form.page++
      while (this.form.page < lastPage + 1) {
        this.getBonusNews()
        this.form.page++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// TODO fixed 高度設定
.pt-100 {
	padding-top: 100px;
}

.bonus {
	&__more {
		right: 16px;
		bottom: 14px;
		z-index: 2;
	}

	&__description {
		line-height: 24px;
	}

	&__item-bg {
		right: 0;
		bottom: 0;
		width: 110px;
		height: 100%;
		background-image:
			linear-gradient(to right,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.47) 36%,
			rgba(0, 0, 0, 0.8) 90%);
	}
}

.z-index-3 {
	z-index: 3;
}

.z-index-4 {
	z-index: 4;
}
</style>
