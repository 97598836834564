<template>
  <v-app :class="style">
    <!-- 設置背景色 -->
    <v-main class="bg">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import isLocalDev from './utils/isLocalDev'
import { deviceCheck } from './utils/device'

export default {
  name: 'App',

  data: () => ({
    data: [],
  }),

  computed: {
    ...mapGetters(['baseImgUrl']),
    ...mapState({
      style: state => state.style,
      favicon: state => state.websiteData.site_params?.favicon,
    }),
    device() {
      return deviceCheck()
    },
  },

  mounted() {
    this.$log(process.env.NODE_ENV, process.env.VUE_APP_THEME, process.env.VUE_APP_API)
  },
}
</script>

<style lang="scss" scoped></style>
